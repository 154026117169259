import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

// constants
import { REGEX } from '~/constants'

// types
import {
  PostStatus,
  type Data,
  type HelpNestedTreeNode,
  type HelpVersion
} from '~/types';

// constants
import { HELP_PAGES_THREE, HELP_VERSIONS } from '~/constants/api-endpoints';

// utils
import get from 'lodash.get';

export const useHelpPageStore = defineStore('help-page', () => {
  const route = useRoute();

  const { query: { preview } } = route;
  const { public: { api_v2: baseURL } } = useRuntimeConfig();

  const currentPageId = ref<null | number>(null);
  const setCurrentPageId = (id: number) => {
    currentPageId.value = id;
  };

  const versions = ref<HelpVersion[]>([]);
  const latestVersion = ref<HelpVersion | null>(null);
  const treeData = ref<HelpNestedTreeNode[]>([]);

  const withDrafts = computed(() => preview === 'true');

  const routeVersion = computed(() => {
    const [firstSegment] = Array.isArray(route.params.slug) ? route.params.slug : [];
    return firstSegment && REGEX.HELP_VERSION.test(firstSegment) ? firstSegment : null;
  });

  const currentVersion = computed(() =>
    versions.value.find(item => item.version === routeVersion.value) ?? latestVersion.value);

  const fetchVersions = async () => {
    try {
      const response = await $fetch<Data<HelpVersion[]>>(HELP_VERSIONS, {
        baseURL,
        params: {
          order_column: 'version',
          order: 'desc',
          per_page: 50,
          ...(!withDrafts.value
            ? { status: PostStatus.PUBLISHED }
            : { 'statuses[]': withDrafts.value ? [PostStatus.DRAFT, PostStatus.PUBLISHED] : [] }),
        },
      });

      versions.value = response.data
      latestVersion.value = response.data?.[0] ?? null;
    } catch (error) {
      console.error(`Error fetching help-versions: ${error}`);
    }
  };

  const fetchTreeData = async () => {
    try {
      const response = await $fetch<HelpNestedTreeNode[]>(HELP_PAGES_THREE, {
        baseURL,
        params: {
          version_id: currentVersion.value?.id,
          'statuses[]': withDrafts.value
            ? [PostStatus.DRAFT, PostStatus.PUBLISHED]
            : [],
        },
      });

      treeData.value = response ?? [];
      currentPageId.value = get(treeData.value, '[0].id', null)
    } catch (error) {
      console.error(`Error fetching help-tree-data: ${error}`);
    }
  };

  watch(
    currentVersion,
    (newVersion, oldVersion) => {
      if (newVersion && newVersion !== oldVersion) {
        fetchTreeData();
      }
    },
  );

  onMounted(async () => {
    await fetchVersions();
    fetchTreeData();
  })

  return {
    versions,
    currentVersion,
    routeVersion,
    treeData,

    withDrafts,

    fetchTreeData,

    currentPageId,
    setCurrentPageId,
  };
});
